// i18next-extract-mark-ns-start blog

import {
  Prismic_Author,
  Prismic_Call_To_Action,
  Prismic_CategoryConnectionEdge,
  Prismic_Post,
  Prismic_PostConnectionEdge
} from '../../types/graphql-types';
import {RichText} from 'prismic-reactjs';
import moment from 'moment/moment';
import {BlogCategoryType, BlogLocale, BlogPost} from '../../types/BlogTypes';
import {Language} from '../../locales/types';
import {TFunction} from 'i18next';
import {setMomentLocale} from './helpers';

function postCategory(category: string, categories: BlogCategoryType[]) {
  return categories.find((cat) => cat.slug === category);
}

export function getBlogPostFromEdge(post: Prismic_Post, categories: BlogCategoryType[]): BlogPost {
  const faq_section = post.slice_zone?.find((slice) => slice.type === 'faq_section');
  const blogPost: BlogPost = {
    ...(post._meta.uid
      ? {
          uid: post._meta.uid,
          slug: '/blog/' + post._meta.uid + '/'
        }
      : {}),
    title: post.title || [],
    updated_at: post.updated_at ? moment(post.updated_at).format('LL') : undefined,
    meta_title: RichText.asText(post.meta_title || post.title || []),
    meta_description: RichText.asText(post.meta_description || []),
    summary: post.summary || [],
    ...(post.category
      ? {
          category: postCategory(post.category, categories),
          categorySlug: '/blog/' + post.category + '/'
        }
      : {}),
    ...(post.secondary_category
      ? {secondary_category: postCategory(post.secondary_category, categories)}
      : {}),
    body: post.body || [],
    cta: post.cta as Prismic_Call_To_Action,
    author: post.author as string,
    author_card: post.author_card as Prismic_Author,
    tags: post._meta.tags,
    image_source: post.image_source || [],
    imageUrl: post.image?.url,
    imageWidth: post.image?.dimensions.width || 1,
    imageHeight: post.image?.dimensions.height || 1,
    custom_html: post.custom_html || '',
    imageRatio: 100,
    firstPublicationDate: moment(post._meta.firstPublicationDate || new Date()).format('LL'),
    ...(post._meta.lang ? {lang: post._meta.lang.substr(0, 2) as Language} : {}),
    faqs:
      faq_section?.fields?.map((field) => ({
        title: field?.title?.[0]?.text || '',
        text: field?.text || []
      })) || [],
    faqsTitle: faq_section?.primary?.faqs_title?.[0].text || '',
    faqsDescription: faq_section?.primary?.faqs_description?.[0].text || ''
  };
  blogPost.imageRatio = ((blogPost.imageHeight || 1) / (blogPost.imageWidth || 1)) * 100;

  return blogPost;
}

type GetPostsParams = {
  posts: Prismic_PostConnectionEdge[];
  categories: BlogCategoryType[];
  tag?: string;
};
export function getPostsFromPrismicEdges({posts, categories, tag}: GetPostsParams): BlogPost[] {
  return posts
    .filter((post) => !tag || post.node._meta.tags?.includes(decodeURI(tag)))
    .map((post: Prismic_PostConnectionEdge) => getBlogPostFromEdge(post.node, categories))
    .sort((a, b) => {
      if (!a.updated_at && !b.updated_at) {
        const secondaryDiff = moment(b.firstPublicationDate).diff(moment(a.firstPublicationDate));
        return !secondaryDiff ? 0 : secondaryDiff < 0 ? -1 : 1;
      }
      if (!a.updated_at) {
        return 1;
      }
      if (!b.updated_at) {
        return -1;
      }
      const primaryDiff = moment(b.updated_at).diff(moment(a.updated_at));
      return primaryDiff < 0 ? -1 : 1;
    });
}

export const removeDuplicatedPosts = (posts: BlogPost[]) => {
  const postsIds = Array.from(new Set(posts.map((post) => post.uid)));
  return postsIds
    .map((id) => posts.find((post) => post.uid === id))
    .filter((post) => Boolean(post)) as BlogPost[];
};

export const sortPostsByDate = (posts: BlogPost[], lang: BlogLocale = 'en-gb') => {
  const sortedPosts = posts.sort((a, b) => {
    const mostRecentDateA = a.updated_at
      ? moment(a.firstPublicationDate).isAfter(moment(a.updated_at))
        ? a.firstPublicationDate
        : a.updated_at
      : a.firstPublicationDate;
    const mostRecentDateB = b.updated_at
      ? moment(b.firstPublicationDate).isAfter(moment(b.updated_at))
        ? b.firstPublicationDate
        : b.updated_at
      : b.firstPublicationDate;
    const isAfter = moment(mostRecentDateA).isAfter(mostRecentDateB);
    return isAfter ? -1 : 1;
  });
  setMomentLocale(lang);
  return sortedPosts.map((post) => ({
    ...post,
    firstPublicationDate: moment(post.firstPublicationDate).format('LL'),
    updated_at: post.updated_at ? moment(post.updated_at).format('LL') : undefined
  }));
};

export const mapCategory = (category: Prismic_CategoryConnectionEdge) => ({
  title: category.node.title,
  slug: category.node._meta.uid,
  order: category.node.order,
  lang: category.node._meta.lang,
  meta_description: category.node.meta_description
});

export const getBlogCaption = (post: BlogPost, t: TFunction) => {
  const author = post.author_card?.author_name
    ? RichText.asText(post.author_card?.author_name) + ' | '
    : '';
  const publicationDate =
    (post.updated_at ? t('Originally published') : t('Published')) +
    ': ' +
    post.firstPublicationDate;
  const updatedAt = post.updated_at ? ' | ' + t('Updated') + ': ' + post.updated_at : '';
  return author + publicationDate + updatedAt;
};

// Utility function to truncate text
export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'; // Add ellipsis if truncated
  }
  return text;
};

