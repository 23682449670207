import styled, {css} from 'styled-components';
import { StyledUl } from "./StyledSx";

export const List = styled(StyledUl)<{listStyle?: string}>`
  list-style: ${props => props.listStyle || 'inherit'};
  margin-left: ${props => props.listStyle === 'none' ? 0 : '1.64rem'};
  .flex-list {
    li {
      display: flex;
    }
  }
  .right-list {
    margin-right: 1.64rem;
    li {
      position: relative;
      text-align: right;
      direction: rtl;
    }
  }
`;

export const OrderedList = styled.ol<{align?: 'left' | 'right'}>`
  ${(props) =>
    props.align === 'right' &&
    css`
      margin-right: 1.64rem;
      li {
        position: relative;
        text-align: right;
        direction: rtl;
      }
    `}
`;
