import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {Section, SectionHeader} from './Section';
import styled from 'styled-components';
import {containerSize, ScreenSizes} from '../../types/responsive';
import {useKeenSlider} from 'keen-slider/react';
import {Button} from './Button';
import {truncateText} from 'utils/blog-utils';
import {Content} from './Content';

// Estilos de la imagen
const CardImage = styled.div`
  overflow: hidden;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0;
`;

// Estilos del contenido
const CardContent = styled.div`
  flex-grow: 1; /* Allow content to grow and take available space */
  display: flex;
  flex-direction: column; /* Stack title and publication date vertically */
  justify-content: space-between;
  padding: 15px;
`;

const Title = styled.p`
  text-align: left;
  margin-top: 0;
`;

const Summary = styled.p`
  text-align: left;
  font-size: 0.9em;
  color: #666;
  margin: 8px 0;
  border-top: 1px solid #e0e0e0;
  padding-top: 8px;
`;

const PostsWrapper = styled.div`
  max-width: ${containerSize};
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 18px;
  padding: 4px 20px;
`;

const Slider = styled.div`
  overflow: hidden;
  position: relative;
  @media (max-width: ${ScreenSizes.md}) {
    overflow: visible !important;
  }
`;

const Card = styled.div`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  margin: 20px;
  display: flex;
  flex-direction: column;
  height: 100%; /* Allow the card to take full height of the wrapper */
  justify-content: space-between; /* Space between title and publication date */
`;

const Wrapper = styled.div<{
  direction?: 'row' | 'column';
  gap?: string;
  justify?:
    | 'space-between'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-around'
    | 'space-evenly';
}>`
  height: 100%;
  display: flex;
  position: relative;
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: center;
  flex-direction: ${(props) => props.direction || 'row'};
  gap: 6px;
  margin-bottom: 1px;
  @media (max-width: ${ScreenSizes.xs}) {
    flex-direction: column;
  }
`;

const ArrowContainer = styled.div<{left?: boolean; right?: boolean}>`
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 0;
  border-radius: 50%;
  background-color: rgba(17, 17, 17, 0.5);
  box-shadow: rgb(0 0 0 / 30%) 0 4px 12px 0;
  top: calc(50% - 16px);
  left: ${(props) => (props.left ? '6px' : 'unset')};
  right: ${(props) => (props.right ? '6px' : 'unset')};
  z-index: 100;
  cursor: pointer;

  svg {
    position: absolute;
    width: 18px;
    height: 18px;
    left: calc(50% - 9px);
    top: calc(50% - 9px);
    path {
      fill: rgb(255, 255, 255);
    }
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 50%) 0 4px 12px 0;
    background-color: rgba(17, 17, 17);
  }
`;
const SliderArrow: React.FC<
  {left?: boolean; right?: boolean; onClick: () => void} & React.HTMLAttributes<HTMLDivElement>
> = ({left, right, onClick}) => {
  return (
    <ArrowContainer onClick={onClick} left={left} right={right}>
      {left && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.2 24.1">
          <path fill="currentColor" d="M12.05,24.1l-12-12L12.05,0,14.2,2.15l-9.9,9.9L14.2,22Z" />
        </svg>
      )}
      {right && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.2 24.1">
          <path fill="currentColor" d="M2.15,24.1,0,22l9.9-9.9L0,2.15,2.15,0l12,12.05Z" />
        </svg>
      )}
    </ArrowContainer>
  );
};
export const RelatedBlogPosts = ({posts}: any) => {
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: 'snap',
    slides: {perView: 4, spacing: 18},
    breakpoints: {
      ['(max-width: ' + ScreenSizes.lg + ')']: {
        slides: {perView: 3.5, spacing: 16}
      },
      ['(max-width: ' + ScreenSizes.md + ')']: {
        slides: {perView: 3, spacing: 16}
      },
      ['(max-width: ' + ScreenSizes.ms + ')']: {
        slides: {perView: 2.5, spacing: 14}
      },
      ['(max-width: ' + ScreenSizes.sm + ')']: {
        slides: {perView: 2, spacing: 14}
      },
      ['(max-width: ' + ScreenSizes.xs + ')']: {
        slides: {perView: 1, spacing: 12}
      }
    }
  });
  const [hasNext, setHasNext] = useState(true);
  const [hasPrev, setHasPrev] = useState(true);
  useEffect(() => {
    if (!instanceRef.current) return;
    instanceRef.current.update();
    setArrowsState();
  }, [instanceRef]);

  useEffect(() => {
    if (!instanceRef.current) return;
    instanceRef.current.on('dragEnded', setArrowsState);
  }, [sliderRef]);

  const setArrowsState = () => {
    console.log('entrem a setejar state')
    console.log(instanceRef.current?.animator.targetIdx);
    console.log(instanceRef.current?.track.details.maxIdx);

    setHasPrev(instanceRef.current.animator.targetIdx > 0);
    setHasNext(instanceRef.current.animator.targetIdx < instanceRef.current.track.details.maxIdx);
  };

  return (
        <PostsWrapper>
          <Slider
            ref={sliderRef}
            className="keen-slider"
            style={{margin: 0, display: 'flex', flexDirection: 'row'}}>
            {hasPrev && (
              <SliderArrow
                left
                onClick={() => {
                  instanceRef.current?.prev();
                  setArrowsState();
                }}
              />
            )}
            {posts.map((post: any, index: number) => (
              <Wrapper className="keen-slider__slide" direction="column" gap="8px">
                <Card>
                  <CardImage>
                    <Image src={post.imageUrl} alt="Imagen" />
                  </CardImage>
                  <CardContent>
                    <Title>{post.title[0].text}</Title>
                    <Summary>{truncateText(post.summary[0].text, 100)}</Summary>
                    <Button
                      variant="light"
                      onClick={() => window.open(`https://monei.com${post.slug}`, '_blank')}>
                      <Trans>Read more</Trans>
                    </Button>
                  </CardContent>
                </Card>
              </Wrapper>
            ))}
            {hasNext && (
              <SliderArrow
                right
                onClick={() => {
                  instanceRef.current?.next();
                  setArrowsState();
                }}
              />
            )}
          </Slider>
        </PostsWrapper>
  );
};
