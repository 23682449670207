import { AnchorLink } from "components/AnchorLink";
import { Link } from "gatsby-plugin-react-i18next";
import magento_logo from "images/magento_logo.svg";
import octobercms_logo from "images/octobercms_logo.svg";
import prestashop_logo from "images/prestashop_logo.svg";
import salesforce_logo from "images/salesforce_logo.svg";
import shopify_logo from "images/shopify_payment_gateway_logo.svg";
import spreedly_logo from "images/spreedly_logo.svg";
import commercetools_logo from "images/commercetools_logo.svg";
import wix_logo from "images/wix_black_logo.svg";
import woocommerce_logo from "images/woocommerce_logo.svg";
import React from "react";
import styled from "styled-components";
import { StyledDiv } from "./StyledSx";
import { Sx } from "../../types/responsive";

export const PartnersContainer = styled(StyledDiv)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledPartnerLogo = styled(StyledDiv)`
  margin: 20px 40px;
  opacity: 0.7;
  transition: 250ms opacity;
  max-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;

  :hover {
    opacity: 1;
  }
  img {
    margin: 0;
  }
`;

export const PartnerLogo: React.FC<React.ImgHTMLAttributes<HTMLImageElement> & {sx?: Sx}> = (props) => (
  <StyledPartnerLogo sx={props.sx}>
    <img {...props} />
  </StyledPartnerLogo>
);

export const Partners = ({...props}) => (
  <PartnersContainer {...props}>
    <Link to="/shopify-payment-gateway/">
      <PartnerLogo src={shopify_logo} alt="MONEI Payment Gateway" width={210} />
    </Link>
    <Link to="/woocommerce-payment-gateway/">
      <PartnerLogo src={woocommerce_logo} alt="WooCommerce payment gateway" width={270} />
    </Link>
    <AnchorLink href="https://docs.monei.com/docs/e-commerce/salesforce/" target="_blank">
      <PartnerLogo src={salesforce_logo} alt="Salesforce payment gateway" width={200} />
    </AnchorLink>
    <Link to="/prestashop-payment-gateway/">
      <PartnerLogo
        src={prestashop_logo}
        alt="Prestashop payment gateway"
        title="Prestashop payment gateway"
        width={280}
      />
    </Link>
    <Link to="/wix-payment-gateway/">
      <PartnerLogo src={wix_logo} alt="WIX payment gateway" width={100} />
    </Link>
    <PartnerLogo src={magento_logo} alt="Magneto payment gateway" width={220} />
    <PartnerLogo src={commercetools_logo} alt="Commercetools payment gateway" width={280} />
    <PartnerLogo src={octobercms_logo} alt="OctoberCMS payment gateway" width={200} />
    <AnchorLink href="https://docs.spreedly.com/payment-gateways/monei/">
      <PartnerLogo src={spreedly_logo} alt="Spreedly payment gateway" width={180} />
    </AnchorLink>
  </PartnersContainer>
);
