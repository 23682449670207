import styled from 'styled-components';
import {Responsive, ScreenSizes, Sx} from '../../../types/responsive';

export const IndexBackground = styled.div<{sx?: Sx}>`
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    z-index: -1;
    background: #aab4e9;
    -webkit-mask-image: linear-gradient(270deg,white 80%, 80%,transparent 100%);
    @media (min-width: ${ScreenSizes.xl}) {
      ${(props) =>
          props.sx &&
          Object.keys(props.sx).map((key: any) => ({
            ...((props.sx?.[key] as Responsive)?.all
                ? { [key]: (props.sx?.[key] as Responsive)?.all + '!important' }
                : {})
          }))};
      left: ${(props) => (props.sx?.left as Responsive)?.all ?? '70%'};
      top: ${(props) => (props.sx?.top as Responsive)?.all ?? 0};
      height: ${(props) => (props.sx?.height as Responsive)?.all ?? '2000px'};
      width: ${(props) => (props.sx?.width as Responsive)?.all ?? '1800px'};
      transform: ${(props) => (props.sx?.transform as Responsive)?.all ?? 'rotate(-58deg)'};
    }
    
    @media (max-width: ${ScreenSizes.xl}) {
      ${(props) =>
        props.sx &&
        Object.keys(props.sx).map((key: any) => ({
          ...((props.sx?.[key] as Responsive).xl
            ? {[key]: (props.sx?.[key] as Responsive)?.xl + '!important'}
            : {})
        }))};
      left: ${(props) => (props.sx?.left as Responsive)?.xl ?? '70%'};
      top: ${(props) => (props.sx?.top as Responsive)?.xl ?? 0};
      height: ${(props) => (props.sx?.height as Responsive)?.xl ?? '2000px'};
      width: ${(props) => (props.sx?.width as Responsive)?.xl ?? '1800px'};
      transform: ${(props) => (props.sx?.transform as Responsive)?.xl ?? 'rotate(-58deg)'};
    }

    @media (max-width: ${ScreenSizes.lg}) {
      ${(props) =>
        props.sx &&
        Object.keys(props.sx).map((key: any) => ({
          ...((props.sx?.[key] as Responsive).lg
            ? {[key]: (props.sx?.[key] as Responsive).lg + '!important'}
            : {})
        }))};
      left: ${(props) => (props.sx?.left as Responsive)?.lg ?? '70%'};
      top: ${(props) => (props.sx?.top as Responsive)?.lg ?? 0};
      height: ${(props) => (props.sx?.height as Responsive)?.lg ?? '2000px'};
      width: ${(props) => (props.sx?.width as Responsive)?.lg ?? '1800px'};
      transform: ${(props) => (props.sx?.transform as Responsive)?.lg ?? 'rotate(-58deg)'};
    }
    @media (max-width: ${ScreenSizes.md}) {
      display: none;
    }
  }
`;
